












































import { Vue, Component } from "vue-property-decorator";
import { AxiosResponse, AxiosError } from "axios"; // Should use AxiosError as well
import axios from "axios";

class UploadEntry {
  public fileName: string = "";
  public fileSize: number = 0;
}

@Component
export default class Download extends Vue {
  public uploadEntry: UploadEntry = new UploadEntry();

  downloadUrl(id: string) {
    return this.baseUrl() + "d/" + id;
  }

  baseUrl() {
    var getUrl = window.location;
    return getUrl.protocol + "//" + getUrl.host + "/";
  }

  mounted() {
    axios
      .get<UploadEntry>("/api/upload/" + this.$route.params.id)
      .then((response: AxiosResponse<UploadEntry>) => {
        // eslint-disable-next-line
        console.log("Set id to " + response.data);
        this.uploadEntry = response.data;
      })
      .catch(error => {
        alert(error.response.status);
      });
  }
}
