import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue);

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlusCircle,
  faFileAlt,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faFileAlt, faPlusCircle, faTimes);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    humanFileSize(size: number) {
      var i = Math.floor(Math.log(size) / Math.log(1024));
      return (
        Number.parseFloat((size / Math.pow(1024, i)).toFixed(2)) * 1 +
        " " +
        ["B", "kB", "MB", "GB", "TB"][i]
      );
    }
  }
});

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
