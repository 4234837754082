













































import { Vue, Component, Prop } from "vue-property-decorator";

import { AxiosResponse, AxiosError } from "axios"; // Should use AxiosError as well
import axios from "axios";
import Resumable from "resumablejs";

import JQuery from "jquery";
let $ = JQuery;

enum Status {
  Initialized = 1,
  Uploading = 2,
  Done = 3
}

import DragDropBox from "@/components/DragDropBox.vue";
import FileOverviewBox from "@/components/FileOverviewBox.vue";

@Component({
  components: {
    DragDropBox,
    FileOverviewBox
  }
})
export default class Home extends Vue {
  private sessionId: string | undefined;
  private userId: string = (Math.random() + 1).toFixed();
  private status: Status = Status.Initialized;
  private nrFiles: number = 0;
  private totalSize: number = 0;

  private resumable: Resumable = new Resumable({
    target: "/api/upload/fileupload/",
    chunkSize: 2 * 1024 * 1024,
    forceChunkSize: true,
    simultaneousUploads: 3,
    uploadMethod: "PUT",
    testChunks: false,
    fileParameterName: "file",
    identifierParameterName: "id",
    chunkNumberParameterName: "chunkNumber",
    totalChunksParameterName: "totalChunks",
    maxFiles: 1
  });

  private upload() {
    let promises = Array<Promise<any>>();

    this.resumable.files.forEach((file: Resumable.ResumableFile) => {
      promises.push(
        axios
          .post("/api/upload/startupload", {
            fileName: file.fileName,
            fileSize: file.size
          })
          .then((response: AxiosResponse) => {
            file.uniqueIdentifier = response.data.id;

            // eslint-disable-next-line
            console.log("Set id to " + response.data.id);
          })
      );
    });

    Promise.all(promises).then(() => {
      // eslint-disable-next-line
      console.log("Now we can upload");
      this.resumable.upload();
    });
  }

  filesUpdated() {
    this.nrFiles = this.resumable.files.length;

    let tmpSize: number = 0;
    this.resumable.files.forEach((file: Resumable.ResumableFile) => {
      tmpSize += file.size;
    });

    this.totalSize = tmpSize;
  }

  mounted() {
    this.resumable.assignDrop($(".resumable-drop")[0]);
    this.resumable.assignDrop($(".resumable-drop")[1]);
    this.resumable.assignBrowse($(".resumable-browse")[0], false);
    this.resumable.assignBrowse($(".resumable-browse")[1], false);
    this.resumable.assignBrowse($(".resumable-browse")[2], false);
    // this.resumable.on("fileAdded", (file: Resumable.ResumableFile) => {});

    this.resumable.on("pause", () => {
      // eslint-disable-next-line
      console.log("Resumable paused");
    });

    this.resumable.on("fileAdded", this.filesUpdated);

    this.resumable.on(
      "fileSuccess",
      (file: Resumable.ResumableFile, message: string) => {
        // eslint-disable-next-line
        console.log(`Resumable fileSuccess: ${file.fileName}`);
        file.progress(false);

        axios
          .post("/api/upload/endupload/" + file.uniqueIdentifier, {
            id: file.uniqueIdentifier
          })
          .then((response: AxiosResponse) => {
            // eslint-disable-next-line
            console.log(`endupload done: ${response.data}`);
            this.$router.push({ path: `/download/${response.data}` });
          });
      }
    );

    this.resumable.on(
      "fileError",
      (file: Resumable.ResumableFile, message: string) => {
        alert(`An error occured while uploading ${file.fileName}`);
      }
    );

    this.resumable.on("uploadStart", () => {
      this.status = Status.Uploading;
    });

    this.resumable.on("complete", () => {
      this.status = Status.Done;
    });
  }
}
