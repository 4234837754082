





























































import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import Resumable from "resumablejs";

@Component
export default class FileOverviewBox extends Vue {
  @Prop() private files!: Resumable.ResumableFile[];
  @Prop() private status!: number;
  @Prop() private totalSize!: number;
}
